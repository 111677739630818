import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { IUserData } from "../types";
import apiInstance from "../../api/instance";

interface UserState {
  isLoggedIn: boolean;
  userData: IUserData | null;
}

interface LoginPayloadI {
  user_name: string;
  password: string;
  ip_address: string;
}

const initialState: UserState = {
  isLoggedIn: false,
  userData: null,
};

export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (formData: LoginPayloadI, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await apiInstance.post("auth/admin-login", formData);
      const data = await response.data;
      localStorage.setItem("token", data?.token);
      localStorage.setItem("user_data", JSON.stringify(data?.data));
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.data);
    }
  }
);

export const userLogout = createAsyncThunk("user/userLogout", async (_: undefined, { rejectWithValue }) => {
  await apiInstance
    .get("auth/logout")
    .then((data) => {
      window.location.replace("/");
      return data;
    })
    .catch((err) => rejectWithValue(err?.data));
});

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    // login: (state, action) => {
    //   // console.log("login state", action.payload);
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.userData = payload.data;
        state.isLoggedIn = true;
      })
      .addCase(userLogin.pending, (state, { payload }) => {
        //
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state = initialState;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.userData = null;
      })
      .addCase(userLogout.rejected, (state) => {
        state.isLoggedIn = false;
        state.userData = null;
      });
  },
});

// export const { login } = userReducer.actions;

export default userReducer.reducer;
