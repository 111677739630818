import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

export const apiInstance = axios.create({
  baseURL: "https://ca-sec.betmaverick.in/api/",
});

// Add a request interceptor
apiInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");

  if (token) config.headers.Authorization = `${token}`;

  return config;
});

// Add a response interceptor
apiInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    // Handle 401 errors
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await apiInstance.get(url, { ...config });

  return res.data;
};

export default apiInstance;
